import axios, { Axios, AxiosError, AxiosRequestConfig } from "axios";
import { headers } from "next/dist/client/components/headers";
import { getJWTFromSession } from "./utils/get-jwt-from-session";

interface PostData {
  [key: string]: string | string[] | Date | number | PostData | undefined | boolean | never[];
}

interface PutData {
  [key: string]: string | null | number | PutData | undefined;
}

class Http {
  axiosInstance: Axios;
  constructor(baseUrl: string) {
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      headers: {
        "Cache-Control": "no-cache",
      },
    });
  }

  download = async (url: string, type: string) => {
    const jwt = await getJWTFromSession();
    const authHeader = jwt
      ? {
          Authorization: `JWT ${jwt}`,
        }
      : {};
    const config: AxiosRequestConfig = {
      maxBodyLength: Infinity,
      responseType: "arraybuffer",
      headers: {
        "Content-Type": type,
        Accept: type,
        ...authHeader,
      },
    };
    return this.axiosInstance.get(url, config);
  };

  get = async <
    ResponseType extends Record<string, unknown> | Record<string, unknown>[],
  >(
    url: string,
    headers: PostData = {},
    data: string = "",
    jwtToken?: string,
  ) => {
    let jwt = "";
    if (jwtToken) {
      jwt = jwtToken;
    } else {
      jwt = (await getJWTFromSession()) ?? "";
    }
    const authHeader = jwt
      ? {
          Authorization: `JWT ${jwt}`,
        }
      : {};
    const config = {
      headers: {
        ...headers,
        ...authHeader,
      },
      data,
    };
    try {
      const response = await this.axiosInstance.get<any, ResponseType>(
        url,
        config,
      );
      return response;
    } catch (error) {
      throw new Error(`Algo salió mal - ${error}`);
    }
  };

  post = async <ResponseType extends Record<string, unknown>>(
    url: string,
    data: PostData,
  ) => {
    const jwt = await getJWTFromSession();
    const authHeader = jwt
      ? {
          Authorization: `JWT ${jwt}`,
        }
      : {};
    const config = {
      headers: {
        ...headers,
        ...authHeader,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await this.axiosInstance.post<any, ResponseType>(
        url,
        JSON.stringify(data),
        config,
      );
      return response;
    } catch (error) {
      if (error instanceof AxiosError) {
        return error;
      } else {
        throw new Error(`${error}`);
      }
    }
  };

  put = async <ResponseType extends Record<string, unknown>>(
    url: string,
    data: PutData
  ) => {
    try {
      const jwt = await getJWTFromSession();
      const authHeader = jwt
        ? {
            Authorization: `JWT ${jwt}`,
          }
        : {};
      const config = {
        headers: {
          ...authHeader,
        },
      };
      const response = await this.axiosInstance.put<any, ResponseType>(
        url,
        data,
        config,
      );
      return response;
    } catch (error) {
      if (error instanceof AxiosError) {
        return error;
      } else {
        throw new Error(`${error}`);
      }
    }
  };

  delete = async <ResponseType extends Record<string, unknown>>(
    url: string,
  ) => {
    try {
      const jwt = await getJWTFromSession();
      const authHeader = jwt
        ? {
            Authorization: `JWT ${jwt}`,
          }
        : {};
      const config = {
        headers: {
          ...authHeader,
        },
      };
      const response = await this.axiosInstance.delete<any, ResponseType>(
        url,
        config,
      );
      return response;
    } catch (error) {
      if (error instanceof AxiosError) {
        return error;
      } else {
        throw new Error(`${error}`);
      }
    }
  };
}

export const clientHttp = new Http("/api");
export const serverHttp = new Http(process.env.BACKEND_URL || "");
