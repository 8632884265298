"use client";
import React, { useState } from "react";
import { useFormState, useFormStatus } from "react-dom";
import { authenticate } from "@/utils/authenticate";
import styles from "./styles.module.scss";
import { clientHttp } from "@/http";
import { useRouter } from 'next/navigation';

const SubmitButton = () => {
  const { pending } = useFormStatus();

  return <button type="submit">{pending ? "Cargando" : "Ingresar"}</button>;
};

const LoginForm = () => {
  const [errorMessage, dispatch] = useFormState(authenticate, undefined);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const router = useRouter();

  const handleForgotPasswordClick = () => {
    setShowForgotPassword(true)
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const result = await authenticate(undefined, formData);

    if (result.success) {
      router.push(result.redirectPath);
    } else {
      console.error(result.error);
    }
  };

  const handleForgotPasswordSubmit = async () => {
    try {
      const resetPasswordRequest = await clientHttp.post("users/password", {
        user: {
          email: forgotPasswordEmail,
        },
      })

      if (resetPasswordRequest.status === 200) {
        setInfoMessage("Se ha enviado un correo con las instrucciones para restablecer tu contraseña.")
        setShowForgotPassword(false)
      } else {
        setInfoMessage("No se encontró un usuario con ese correo.")
      }
    } catch (error) {
      setInfoMessage("Hubo un error al enviar el correo.")
    }
  }

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <h1>Bienvenido</h1>
      {errorMessage && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}
      <input
        id="username"
        type="email"
        name="username"
        placeholder="Nombre de usuario"
        required
      />
      <input
        type="password"
        id="password"
        name="password"
        placeholder="Contraseña"
        required
      />
      <SubmitButton />
      {infoMessage && (
        <div className={styles.infoMessage}>{infoMessage}</div>
      )}
      <div className={styles.forgotPasswordContainer}>
        <span
          className={styles.forgotPasswordText}
          onClick={handleForgotPasswordClick}
        >
          Olvidé mi contraseña
        </span>
        {showForgotPassword && (
          <div className={styles.forgotPasswordForm}>
            <input
              type="email"
              placeholder="Ingresa tu correo"
              className={styles.emailInput}
              value={forgotPasswordEmail} 
              onChange={(e) => setForgotPasswordEmail(e.target.value)}
            />
            <button
              type="button"
              className={styles.confirmButton}
              onClick={handleForgotPasswordSubmit}
            >
              Reiniciar contraseña
            </button>
          </div>
        )}
      </div>

    </form>
  );
};

export default LoginForm;